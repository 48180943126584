<template>
  <div class="row" style="padding-top: 20px">
    <div class="col">
      <b-card no-body style="max-width: 20rem" class="mb-2">
        <b-card-body>
          <b-link to="/">
            <h4>{{ totalReportContent.Meteorer }}</h4>
          </b-link>
          <p class="card-text">Meteorer</p>
        </b-card-body>
      </b-card>
    </div>

    <div class="col">
      <b-card
        no-body
        bg-variant="secondary"
        text-variant="white"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-body>
          <b-link to="/?meteortypefilter=krysspeilet">
            <h4>{{ totalReportContent.Krysspeilede }}</h4>
          </b-link>
          <p class="card-text">Krysspeilede</p>
        </b-card-body>
      </b-card>
    </div>

    <div class="col">
      <b-card
        no-body
        bg-variant="dark"
        text-variant="white"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-body>
          <b-link to="/?meteortypefilter=meteorittkandidat">
            <h4>{{ totalReportContent.Meteorittkandidater }}</h4>
          </b-link>
          <p class="card-text">Meteorittkandidater</p>
        </b-card-body>
      </b-card>
    </div>

    <div class="col">
      <b-card
        title="0"
        bg-variant="success"
        text-variant="white"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text> Medieomtale </b-card-text>
      </b-card>
    </div>

    <div class="col">
      <b-card
        title="0"
        bg-variant="warning"
        text-variant="white"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text> Leteaksjon </b-card-text>
      </b-card>
    </div>

    <div class="col">
      <b-card
        title="0"
        bg-variant="danger"
        text-variant="white"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text> Meteorittfunn </b-card-text>
      </b-card>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <b-tabs content-class="mt-3">
        <b-tab title="Stasjonstatistikk" active>
          <div class="table-responsive">
            <table
              id="tableComponent"
              class="table table-bordered table-striped table-sm"
            >
              <thead>
                <tr>
                  <!-- loop through each value of the fields to get the table header -->
                  <th v-for="field in stationReportContentFields" :key="field" class="verticalOnSmallerScreens">
                    {{ field }}
                    <i class="bi bi-sort-alpha-down" aria-label="Sort Icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Loop through the list get the each student data -->
                <tr v-for="item in stationReportContent" :key="item">
                  <td v-for="field in stationReportContentFields" :key="field">
                    <span v-if="field == 'Stasjonsnavn'"
                      ><a :href="'/?stasjonsfilter=' + item[field]">{{
                        item[field]
                      }}</a></span
                    >
                    <span v-else>{{ item[field] }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <b-tab title="Kamerastatistikk">
          <div class="table-responsive">
            <table
              id="tableComponent"
              class="table table-bordered table-striped table-sm"
            >
              <thead>
                <tr>
                  <!-- loop through each value of the fields to get the table header -->
                  <th v-for="field in camReportContentFields" :key="field">
                    {{ field }}
                    <i class="bi bi-sort-alpha-down" aria-label="Sort Icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Loop through the list get the each student data -->
                <tr v-for="item in camReportContent" :key="item">
                  <td v-for="field in camReportContentFields" :key="field">
                    {{ item[field] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>



<script>
import MeteorService from "../../services/meteor.service";
export default {
  name: "Statistics",
  data() {
    return {
      totalReportContent: "",
      stationReportContent: [],
      stationReportContentFields: [
        "Stasjonsnavn",
        "ForsteObservasjonsTidspunkt",
        "SisteObervasjonsTidspunkt",
        "DagerMedObservasjoner",
        "DagerSidenSisteObservasjon",
        "Kameraopptak",
        "Meteorer",
        "Krysspeilede",
        "Meteorittkandidater",
      ],
      camReportContent: [],
      camReportContentFields: [
        "Stasjonsnavn",
        "Kameranavn",
        "ForsteObservasjonsTidspunkt",
        "SisteObervasjonsTidspunkt",
        "DagerMedObservasjoner",
        "DagerSidenSisteObservasjon",
        "Kameraopptak",
        "Meteorer",
        "Krysspeilede",
        "Meteorittkandidater",
      ],
    };
  },
  mounted() {
    MeteorService.getTotalReport().then(
      (response) => {
        this.totalReportContent = response.data;
      },
      (error) => {
        this.totalReportContent =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    MeteorService.getStationReport().then(
      (response) => {
        this.stationReportContent = response.data;
      },
      (error) => {
        this.stationReportContent =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    MeteorService.getCamReport().then(
      (response) => {
        this.camReportContent = response.data;
      },
      (error) => {
        this.camReportContent =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>
<style>
@media screen and (min-width: 0px) and (max-width: 850px) {
  .hideOnSmallScreens {
    display: none;
  }
}
@media screen and (min-width: 0px) and (max-width: 850px) {
  .verticalOnSmallerScreens {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}
@media screen and (min-width: 0px) and (max-width: 850px) {
  .smallerOnSmallScreens {
    font-size: 0.9rem;
  }
}
</style>